import Loading from "./Components/Loading";
import Navigation from "./Components/Navigation";
import Destinations from "./Pages/Destinations";
import LandPage from "./Pages/LandingPage";
import BackgroundVideo from "../src/Components/Background";
import "./root.css";
import Services from "./Pages/Services";

const App = () => {


  return (
    <div>
      <BackgroundVideo />

      <main>


        
      </main>


      {/* 

            <Navigation />

                  <LandPage />
        <Services className='services' data-color="white" />


      
      <Loading />
      <Destinations />

      */}
    </div>
  );
};

export default App;
